<template>

<div class="mt-nav bgcolor-white">
<static-banner :image_banner="image_banner"/>

<div class="pt-4r">
<el-row class="center-content">
<el-col :xl="4" :lg="4" :md="4" :sm="24" :xs="24">
<horizontal-text content="企业介绍"/>
</el-col>
<el-col :xl="20" :lg="20" :md="20" :sm="24" :xs="24" class="pt-2r base-content">
<div style="margin-left: 17%;text-align: justify;">
            澳门威斯尼斯人wns8（简称“斯纳欧”）是吉林省信息行业领军企业之一，公司位于长春市高新技术产业开发区顺达路333号长春中日智能制造产业园4楼，成立于2011年，2017年成立大连分公司，2021年成立北京分公司。
          </div>
<div class="pt-2r" style="margin-left: 17%;text-align: justify;">
            斯纳欧深耕行业十余年，凭借完善的项目管理能力和售后服务水平，获得了政府部门和各企事业单位的一致认可，已成为省内知名的信息技术企业。公司通过资源整合以及威斯尼斯人wns145585，从传统的软件研发型企业，成长为集软件产品研发、信息技术服务、数字工厂解决方案服务、软件人才培养、产业园运营于一体的综合型企业。斯纳欧与吉林大学、东北师范大学、吉林农业大学等高等院校建立了校企合作关系，为大学生提供实习和就业平台。2012年至今，公司已为行业培养了700余名软件开发人才。
          </div>
<div class="pt-2r"></div>
<video preload="auto" loop controls style="width: 100%;">
<source :src="require('@/assets/video/sunao-h264-480p.mp4')">
</video>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
<div class="bgcolor-gray pt-4r">
<el-row class="center-content">
<el-col :xl="4" :lg="4" :md="4" :sm="24" :xs="24">
<horizontal-text content="质量方针"/>
<el-row style="font-size: 1.25rem;">
<el-col :xl="24" :lg="24" :md="24" :sm="12" :xs="12" class="pt-2r">品质第一</el-col>
<el-col :xl="24" :lg="24" :md="24" :sm="12" :xs="12" class="pt-2r">顾客至上</el-col>
<el-col :xl="24" :lg="24" :md="24" :sm="12" :xs="12" class="pt-2r">精益求精</el-col>
<el-col :xl="24" :lg="24" :md="24" :sm="12" :xs="12" class="pt-2r">鼓励创新</el-col>
</el-row>
</el-col>
<el-col :xl="20" :lg="20" :md="20" :sm="24" :xs="24" class="pt-2r">
<el-image :src="quality_policy" fit="cover"/>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
</div>
</template>

<script>
import HorizontalText from "@/components/HorizontalText";
import StaticBanner from "@/components/StaticBanner";


export default {
  name: "EnterpriseIntro",
  components: {HorizontalText, StaticBanner},
  data() {
    return {
      image_banner: require('@/assets/images/banner-about-sunao-1.jpg'),
      // image_banner: require('@/assets/images/banner-about-sunao-2.jpg'),
      quality_policy: require('@/assets/images/quality-policy.jpg')
    }
  }
}
</script>

<style scoped>
.banner-content {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  align-items: center;
  color: #fff;
  font-size: 2.25rem;
  font-weight: 700;
  margin-top: -1.125rem;
}

.base-content {
  font-size: 1.125rem;
  line-height: 2.2rem;
}
</style>