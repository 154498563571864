import { render, staticRenderFns } from "./InformationCom.vue?vue&type=template&id=dae2491a&scoped=true&"
import script from "./InformationCom.vue?vue&type=script&lang=js&"
export * from "./InformationCom.vue?vue&type=script&lang=js&"
import style0 from "./InformationCom.vue?vue&type=style&index=0&id=dae2491a&prod&scoped=true&lang=css&"



import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae2491a",
  null
  
)

export default component.exports