<template>
<div id="app">

<NavBar/>

<router-view v-if="routerAlive" :key="key"></router-view>

<FollowUs/>

<Footer/>

<Copyright/>
</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Copyright from "@/components/Copyright";
import FollowUs from "@/components/FollowUs";

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    Copyright,
    FollowUs
  },
  data() {
    return {
      routerAlive: true
    }
  },
  computed: {
    key() {
      // 解决同一个页面地址栏参数改变，页面不刷新的问题
      return this.$route.name ? this.$route.name + new Date() : this.$route + new Date()
    }
  },
}
</script>

<style>

</style>
