<template>

<div class="position-relative">
<el-carousel trigger="click" :height="height" ref="slideCarousel">
<el-carousel-item v-for="(item, index) in banners" :key="index">
<div class="swiper-img-box">
<el-image :src="item.url" fit="cover" class="swiper-img"></el-image>
<div class="banner-content">
<div class="title">{{ item.title }}</div>
<div class="sub-title">{{ item.subTitle }}</div>
</div>
</div>
</el-carousel-item>
</el-carousel>
</div>
</template>

<script>
export default {
  name: "Swiper",
  data() {
    return {
      height: "37.5rem",
      banners: [
        {url: require('@/assets/images/carousel-1.jpg'), title: '软件定义世界，智能引领未来', subTitle: '以科技创造美好生活、以创新驱动产业发展'},
        {url: require('@/assets/images/carousel-2.jpg'), title: '技术赋能，服务至上', subTitle: '通过信息化服务，助力用户打造核心竞争力'},
        {url: require('@/assets/images/carousel-3.jpg'), title: '发展数字经济，推动产业发展', subTitle: '以创新为引领，用数字化为产业赋能'}
      ]
    }
  },
  mounted() {
    this.slideBanner()
  },
  methods: {
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      let box = document.querySelector('.el-carousel__container');
      let startPoint = 0;
      let stopPoint = 0;
      //重置坐标
      let resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        if (stopPoint === 0 || startPoint - stopPoint === 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },
  }
}
</script>

<style scoped>
.swiper-img-box {
  width: 100%;
  height: 37.5rem;
}

.swiper-img {
  width: 100%;
  height: 100%;
}

.swiper-img-box .banner-content {
  position: absolute;
  left: 10%;
  top: 50%;
  margin-top: -3.125rem;
  z-index: 20;
  height: 6.25rem;
  text-align: left;
}

.banner-content .title {
  color: #fff;
  font-size: 2.25rem;
  font-weight: 800;
}

.banner-content .sub-title {
  color: #fff;
  font-size: 1.5rem;
}

/deep/ .el-carousel__button {
  width: 80px;
}

/deep/ .el-carousel__arrow {
  width: 3.125rem;
  height: 3.125rem;
  font-size: 3.125rem;
  background-color: transparent;
}

/deep/ .el-carousel__arrow:hover {
  background-color: transparent;
}

@media screen and (max-width: 992px) {
  /deep/ .el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  /deep/ .el-carousel__arrow {
    display: none;
  }

  .swiper-img-box .banner-content {
    left: 4%;
  }
}
</style>