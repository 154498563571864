<template>

<div class="mt-nav">
<div class="bgcolor-gray">
<static-banner :image_banner="image_banner" main-title="资质荣誉"/>
</div>

<div class="bgcolor-gray pt-4r">
<el-row class="center-content">
<el-col :span="24">
<horizontal-text content="资质认证"/>
</el-col>
<el-col :xl="6" :lg="6" :md="6" :sm="24" :xs="24" v-for="(item, index) in qualifications" :key="index"
                class="pt-2r">
<div class="img-container">
<el-image v-show="item.url" class="quality-image bgcolor-white" fit="contain" :src="item.url"/>
<div v-show="!item.url" class="quality-image bgcolor-white quality-image-title">
<div class="quality-image-title-inner">{{ item.title }}</div>
</div>
<div class="img-title text-center pt-1r" v-html="item.title"></div>
</div>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
<div class="bgcolor-white pt-4r">
<el-row class="center-content">
<el-col :span="24">
<horizontal-text content="荣誉奖项"/>
</el-col>
<el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24" v-for="(item, index) in honors" :key="index"
                class="bg-honor text-center">
<div style="width: 100%;height: 7.875rem;">
<el-image :src="honor_1" fit="contain" style="height: 7.875rem;float: left;"/>
<el-image :src="honor_2" fit="contain" style="height: 7.875rem;float: right;"/>
</div>
<div class="honor-text">
<div class="honor-text-inner" v-html="item"></div>
</div>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
</div>
</template>

<script>
import HorizontalText from "@/components/HorizontalText";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "Qualification",
  components: {HorizontalText, StaticBanner},
  data() {
    return {
      image_banner: require('@/assets/images/banner-honor.jpg'),
      honor_1: require('@/assets/images/honor-1.png'),
      honor_2: require('@/assets/images/honor-2.png'),
      qualifications: [{
        title: "ISO9001:2015<br/>质量管理体系认证",
        url: require('@/assets/images/ISO-9001-2015-logo-1-1000x1000.png')
      }, {
        title: "ISO/IEC27001:2013<br/>信息安全管理体系认证",
        url: require('@/assets/images/ISO-27001-2013-logo-1-1000x1000.png')
      }, {
        title: "高新技术企业",
        url: require('@/assets/images/高新技术企业.jpg')
      }, {
        title: "吉林省科技小巨人企业",
        url: ""
      }, {
        title: "市级企业技术中心",
        url: ""
      }, {
        title: "软件企业认定",
        url: ""
      }],
      honors: [
        "全国模范劳动关系和谐企业",
        "吉林省创业孵化基地",
        "国家双创示范基地产业双创示范点",
        "吉林省服务贸易协会副会长单位",
        "吉林省软件行业协会副会长单位",
        "“吉林省软件产业20年”突出贡献企业",
        "华为云优秀供应商",
        "2021吉林省自主数字化装备和安全解决方案优秀服务商",
        "疫情防控“爱心企业”",
        "长春市捐资助学贡献单位",
        "2019吉林省信息产业百强企业",
        "2018年度科技创新先进企业10强"
      ]
    }
  }
}
</script>

<style scoped>
.img-container {
  width: 100%;
  height: 15rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.quality-image {
  width: 100%;
  height: 10rem;
  padding: 0.5rem;
}

.quality-image-title {
  font-weight: 600;
  color: #b1996d;
  font-size: 1.5rem;
  position: relative;
}

.quality-image-title-inner {
  width: calc(100% - 1rem);
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  text-align: center;
}

.img-title {
  font-weight: 600;
  height: 5rem;
  font-size: 1.125rem;
}

.bg-honor {
  padding: 2rem 0.5rem 0 0.5rem;
  position: relative;
}

.honor-text {
  position: absolute;
  width: 100%;
  height: 7rem;
  top: 2rem;
  text-align: center;
  color: #b1996d;
  font-size: 1.5rem;
  padding: 0 4rem;
  display: table;
}

.honor-text-inner {
  display: table-cell;
  vertical-align: middle;
}
</style>