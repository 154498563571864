<template>

<div class="mt-nav bgcolor-white">
<static-banner :image_banner="image_banner" main-title="助力用户数字化转型"
                   sub-title="通过专业的团队和技术，助力用户实现业务信息化、数字化、智能化"/>

<div class="pt-4r">
<el-row class="center-content">
<el-col :span="24">
<horizontal-text content="软件工程服务"/>
</el-col>
<el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24" v-for="(item, index) in services" :key="index"
                class="service-div pt-2r">
<div class="service-content">{{ item.content }}</div>
<div class="service-title text-center pt-1r base-content" v-html="item.title"></div>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
<div class="bgcolor-gray pt-4r">
<div class="center-content">
<horizontal-text content="智能制造"/>
<div class="pt-2r base-content">
          斯纳欧联合合作伙伴，专注于工业自动化、工业物联网、工业工程，利用物联网等技术将生产中的供应、制造、销售等信息数据化、标准化、智慧化，打通企业运营的“信息孤岛”，产品全生命周期追溯，提升产品质量管控能力。
        </div>
<el-row class="center-content">
<el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" v-for="(item, index) in intelligentManufacture"
                  :key="index" class="intelli-image">
<el-image fit="contain" :src="item"/>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
</div>
<div class="pt-4r">
<div class="center-content">
<horizontal-text content="数字化咨询"/>
<div class="pt-2r base-content">
          斯纳欧咨询服务主要包括信息化、智能化、数字化三大方面，以高效、专业的服务，助力各行业用户应对各项挑战。
        </div>
<el-image :src="image_digital" fit="cover" class="pt-2r" style="height: 31.25rem;"/>
<div class="pt-4r"></div>
</div>
</div>
</div>
</template>

<script>
import HorizontalText from "@/components/HorizontalText";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "Service",
  components: {HorizontalText, StaticBanner},
  data() {
    return {
      image_banner: require('@/assets/images/banner-services.jpg'),
      image_digital: require('@/assets/images/数字化咨询.png'),
      services: [{
        title: "服务内容",
        content: "软件设计与开发服务、软件实施服务、软件运维服务、软件产品研发"
      }, {
        title: "服务客户",
        content: "政府部门、企业、事业单位、社会团体、科研机构、高校"
      }, {
        title: "技术路线",
        content: "Java、C++、Python、Objective-C、Oracle、MySQL、Qt、HTML5、JavaScript、Vue、Spring"
      }, {
        title: "软件类型",
        content: "管理信息系统、公共服务平台、应用软件、CMS、ERP、MES、CRM、OA、移动App、小程序"
      }, {
        title: "执行标准",
        content: " 斯纳欧基于ISO 9001质量管理体系和CMMI模型，采用软件工程标准进行软件开发过程管理，确保各工程阶段的标准化作业。"
      }, {
        title: "服务优势",
        content: "斯纳欧建立了完善的“在岸”和“离岸”的交付服务模式，为客户提供全生命周期的软件开发和维护服务，通过定制化的系统和技术专长，助力用户运用信息化理念打造核心竞争力。"
      }],
      intelligentManufacture: [
        require('@/assets/images/intelli-1.png'),
        require('@/assets/images/intelli-2.png'),
        require('@/assets/images/intelli-3.png'),
        require('@/assets/images/intelli-4.png')
      ]
    }
  }
}
</script>

<style scoped>
.base-content {
  font-size: 1.125rem;
  line-height: 2.2rem;
}

.service-div {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 20rem;
}

.service-content {
  width: 100%;
  height: calc(100% - 3rem);
  border: 1px solid #333;
  font-size: 1.25rem;
  line-height: 2.4rem;
  padding: 2rem 2rem;
}

.service-title {
  font-weight: 600;
  height: 3rem;
}

.intelli-image {
  padding: 2rem 2rem 0 2rem;
}
</style>