<template>

<div class="mt-nav">
<static-banner :image_banner="image_banner" main-title="媒体报道"
                   sub-title="以下是相关媒体对我们的报道"/>

<NewsContent news-type="2" content-title="媒体报道"></NewsContent>
</div>
</template>

<script>
import NewsContent from "@/components/NewsContent";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "MediaCoverage",
  components: {
    NewsContent,
    StaticBanner
  },
  data() {
    return {
      image_banner: require('@/assets/images/banner-media-reports.jpg'),
    }
  }
}
</script>

<style scoped>
</style>