<template>
<div>
<div :style="index === lineIndex ? styleArr : [styleArr[0]]" v-for="(item, index) in content" :key="index">
      {{ item }}
    </div>
</div>
</template>

<script>
export default {
  name: "HorizontalText",
  props: {
    // 文字内容
    content: {
      type: String,
      default: "企业介绍"
    },
    // 横线的所在字符串索引
    lineIndex: {
      type: Number,
      default: 0
    },
    fontSize: {
      type: String,
      default: "1.5rem"
    }
  },
  data() {
    return {
      styleArr: [{
        display: "inline-block",
        fontSize: this.fontSize,
        fontWeight: 600
      }, {
        borderBottom: "5px solid #2C9FD0",
        paddingBottom: "7px"
      }]
    }
  }
}
</script>

<style scoped>

</style>