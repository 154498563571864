<template>

<div class="mt-nav">
<static-banner :image_banner="image_banner" main-title="企业资讯"
                   sub-title="以下是我们的最新动态，为你提供威斯尼斯人wns145585的第一手资讯"/>

<NewsContent news-type="1" content-title="企业资讯"></NewsContent>
</div>
</template>

<script>
import NewsContent from "@/components/NewsContent";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "CorporateInformation",
  components: {
    NewsContent,
    StaticBanner
  },
  data() {
    return {
      image_banner: require('@/assets/images/banner-company-news.jpg')
    }
  }
}
</script>

<style scoped>
</style>