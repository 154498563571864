<template>
<div class="home-news">

<div class="hidden-sm-and-down">
<router-link class="news-item common-border-radius transition-hover" :class="{first:index===0}" :to="routerTo">
<div class="news-image-container">
<el-image class="news-cover img-transition-hover" fit="cover"
                    :src="news.newsCover?news.newsCover:defaultUrl">
<div slot="error" class="error_img">
<el-image class="news-cover img-transition-hover" fit="cover" :src="defaultUrl"></el-image>
</div>
</el-image>
</div>
<div class="news-info img-transition-hover">
<div class="title">{{ news.newsTitle }}</div>
<div class="date common-font-size-14">
<div class="date-arrow flex-row-between">{{ parseTime(news.publishTime, 'yyyy年m月d日') }}
              <i class="next-icon el-icon-arrow-right next-medium" v-if="index!==0"></i>
</div>
</div>
</div>
</router-link>
</div>

<div class="hidden-md-and-up mobile-news-container">
<router-link class="news-item common-border-radius transition-hover" :to="routerTo">
<div class="news-image-container">
<el-image class="news-cover img-transition-hover" fit="cover "
                    :src="news.newsCover?news.newsCover:defaultUrl">
<div slot="error" class="error_img">
<el-image class="news-cover img-transition-hover" fit="cover" :src="defaultUrl"></el-image>
</div>
</el-image>
</div>
<div class="news-info">
<div class="title">{{ news.newsTitle }}</div>
<div class="date common-font-size-14">
<div class="date-arrow flex-row-between">{{ parseTime(news.publishTime, 'yyyy年m月d日') }}
              <i class="next-icon el-icon-arrow-right next-medium"></i>
</div>
</div>
</div>
</router-link>
</div>
</div>
</template>

<script>

export default {
  name: "HomeNews",
  props: {
    news: Object,
    index: Number
  },
  data() {
    return {
      routerTo: {
        name: 'HomeNewsDetail',
        params: {id: this.news.newsId}
      },
      defaultUrl: require('@/assets/images/news-default.png')
    }
  },
  methods: {}
}
</script>

<style scoped>
.news-item {
  display: block;
  height: 38.625rem;
  overflow: hidden;
  position: relative;
  -webkit-transform: rotate(0deg);
}

.news-item.first .news-cover {
  width: 100%;
  height: 100%;
}

.el-image {
  display: flex;
}

.news-item.first .news-image-container {
  width: 100%;
  height: 28.125rem;
}

.news-image-container {
  width: 100%;
  height: 20.5625rem;
}

.news-cover {
  width: 100%;
  height: 100%;
}

.news-info {
  background-color: #fff;
  bottom: 0;
  font-size: 1.25rem;
  height: 18.0625rem;
  left: 0;
  padding: 1.875rem;
  position: relative;
  width: 100%;
}

.news-info .title {
  font-size: 1.25rem;
  height: 11.625rem;
}

.news-info .date {
  border-top: .125rem solid hsla(0, 0%, 60%, .581);
  height: 2.3125rem;
  margin: 0 auto;
  padding-top: 1.125rem;
}

.next-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
}

.news-item.first .news-info {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #2c9fd0;
  color: #ffffff;
  height: 10.5rem;
}

.news-item.first .news-info .title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
}

.news-item.first .news-info .date {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-line-pack: center;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-content: center;
  align-items: center;
  border: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
}

.mobile-news-container .news-info {
  height: 16.0625rem;
  padding: 1.5rem 1rem 1rem;
}

.mobile-news-container .news-image-container {
  
  height: 100%;
}

.mobile-news-container .news-info .title {
  height: 9.625rem;
}

.mobile-news-container .news-item{
  height: 100%;
}

.transition-hover:hover .news-cover {
  transform: scale(1.1);
}

.transition-hover:hover .news-info {
  background-color: #2c9fd0;
  color: #ffffff;
}

.transition-hover:hover .date {
  border-top: .125rem solid rgba(255, 255, 255, 0.49);
}

/deep/ .error_img {
  width: 100%;
  height: 100%;
}
</style>