<template>

<div class="home mt-nav">

<Swiper/>
<div class="center-content">

<div class="news-update">
<el-row class="mt-20 mb-20">
<div class="font-24 ml-20 font-weight-700">资讯动态</div>
</el-row>
<el-row :gutter="20">
<div v-for="(item, index) in news" :key="index">
<el-col :xs="24" :sm="24" :md="index===0?16:8" :lg="index===0?16:8" :xl="index===0?16:8">
<div class="news-container mb-20">
<HomeNews :news="item" :index="index"></HomeNews>
</div>
</el-col>
</div>
</el-row>
</div>

<div class="service">
<el-row class="mb-20">
<div class="font-24 ml-20 font-weight-700">服务</div>
</el-row>
<el-row :gutter="20">
<div v-for="(item, index) in services" :key="index">
<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
<div class="service-container mb-20">
<router-link class="service-item transition-hover" to="service">
<div class="service-img-container">
<el-image class="service-img img-transition-hover" fit="cover" :src="item.url"></el-image>
<div class="service-title">{{ item.name }}</div>
</div>
</router-link>
</div>
</el-col>
</div>
</el-row>
</div>
</div>
</div>
</template>

<script>
import Swiper from "@/components/Swiper";
import HomeNews from "@/components/HomeNews";
import {listNews} from "@/api/news";

export default {
  name: "Home",
  components: {
    Swiper,
    HomeNews
  },
  data() {
    return {
      news: [],
      services: [
        {
          name: '软件工程服务',
          url: require('@/assets/images/home-service-1.jpg')
        },
        {
          name: '智能制造',
          url: require('@/assets/images/home-service-2.jpg')
        },
        {
          name: '数字化咨询',
          url: require('@/assets/images/home-service-3.jpg')
        },
      ]
    }
  },
  mounted() {
    this.handleQuery()
  },
  methods: {
    handleQuery() {
      let queryParams = {
        pageNum: 1,
        pageSize: 5,
        newsProperty: '2' // 资讯属性：焦点
      }
      listNews(queryParams).then(res => {
        this.news = res.rows
      })
    },
  }
}
</script>

<style scoped>
.service-item {
  display: block;
  overflow: hidden;
  position: relative;
  -webkit-transform: rotate(0deg);
}

.service-img-container {
  width: 100%;
}

.service-img {
  width: 100%;
  height: 100%;
}

.service-container .service-title {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  z-index: 20;
  width: 100%;
  text-align: center;
  color: #fff;
}

.transition-hover:hover .img-transition-hover {
  transform: scale(1.1);
}

.el-image {
  display: flex;
}
</style>
