<template>

<div class="mt-nav bgcolor-white">
<static-banner :image_banner="image_banner" main-title="产业园"
                   sub-title="打造信息技术和智能制造产业集群，推动数字化产业发展"/>

<div class="pt-4r">
<el-row class="center-content">
<el-col :span="24">
<horizontal-text content="一园二牌"/>
</el-col>
<el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" class="pt-2r">
<div style="width: 80%;margin: 0 auto;">
<div class="park-title">长春高新国际服务外包产业园</div>
<div class="park-content pt-2r">
              2016年3月25日，吉林省商务厅、长春市商务局、长春高新区管委会批准成立，澳门威斯尼斯人wns8负责建设和运营“长春高新国际服务外包产业园”。
            </div>
</div>
</el-col>
<el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" class="pt-2r">
<div style="width: 80%;margin: 0 auto;">
<div class="park-title">长春中日智能制造产业园</div>
<div class="park-content pt-2r">
              2018年2月24日，长春新区批准成立，澳门威斯尼斯人wns8负责建设和运营
              “长春中日智能制造产业园”，为吉林省传统制造业提供智能制造整体解决方案服务。
            </div>
</div>
</el-col>
<el-col :span="24" class="pt-2r text-center">
<el-row style="border: 0.5rem solid #f2f2f2">
<el-col
                :xl="6" :lg="6" :md="6" :sm="12" :xs="12"
                v-for="(item, index) in areaContent"
                :key="index"
                style="margin: 2rem 0;">
<div style="font-size: 1.125rem;">{{ item.title }}</div>
<div class="pt-2r" style="color: #0e6cc4;">
<span style="font-size: 2rem;font-weight: 800;">{{ item.number }}&nbsp;</span>
<span style="font-size: 1.125rem;">{{ item.unit }}</span>
</div>
</el-col>
</el-row>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
<div class="bgcolor-gray pt-4r">
<el-row class="center-content">
<el-col :span="24">
<horizontal-text content="招商对象"/>
</el-col>
<el-col v-for="(item, index) in investments" :key="index" :xl="8" :lg="8" :md="8" :sm="24" :xs="24"
                class="pt-2r text-center" style="position: relative;">
<el-image :src="item.url" fit="cover" style="width: 18.75rem;"/>
<div class="investment-title">{{ item.title }}</div>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
<div class="pt-4r">
<el-row class="center-content">
<el-col :span="24">
<horizontal-text content="园区服务"/>
</el-col>
<el-col v-for="(item, index) in services" :key="index" :xl="6" :lg="6" :md="6" :sm="12" :xs="12"
                class="pt-2r text-center">
<el-image :src="item.url" fit="contain" style="height: 8.25rem;"/>
<div class="service-title">{{ item.title }}</div>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
<div class="bgcolor-gray pt-4r">
<div class="center-content">
<horizontal-text content="建设模式"/>
<el-image :src="image_build" fit="fill" class="pt-2r"/>
<div class="pt-4r"></div>
</div>
</div>
<div class="pt-4r">
<div class="center-content">
<horizontal-text content="园区实景"/>
<div class="pt-2r"></div>
<el-carousel indicator-position="none" :interval="2000" :height="carouselHeight" ref="carouselRef">
<el-carousel-item v-for="(item, index) in banners" :key="index">
<el-image :src="item" fit="fill"/>
</el-carousel-item>
</el-carousel>
<div class="pt-4r"></div>
</div>
</div>
</div>
</template>

<script>
import HorizontalText from "@/components/HorizontalText";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "AboutPark",
  components: {HorizontalText, StaticBanner},
  data() {
    return {
      image_banner: require('@/assets/images/banner-about-park.jpg'),
      image_build: require('@/assets/images/产业园建设模式.png'),
      carouselHeight: "",
      areaContent: [{
        title: "办公面积",
        number: 7200,
        unit: "平方米"
      }, {
        title: "厂房面积",
        number: 1.1,
        unit: "万平方米"
      }, {
        title: "容纳人员",
        number: 500,
        unit: "人"
      }, {
        title: "运营时间",
        number: 2016,
        unit: "年"
      }],
      investments: [{
        title: "信息技术研发和服务企业",
        url: require('@/assets/images/settled-company-1.jpg')
      }, {
        title: "设备生产制造企业",
        url: require('@/assets/images/settled-company-2.jpg')
      }, {
        title: "智能制造服务企业",
        url: require('@/assets/images/settled-company-3.jpg')
      }],
      services: [{
        title: "写字办公",
        url: require('@/assets/images/办公-线性.png')
      }, {
        title: "车间厂房",
        url: require('@/assets/images/工厂.png')
      }, {
        title: "餐饮",
        url: require('@/assets/images/刀叉餐饮.png')
      }, {
        title: "会议路演",
        url: require('@/assets/images/10会议室管理.png')
      }],
      banners: [
        require('@/assets/images/park-01.jpg'),
        require('@/assets/images/park-02.jpg'),
        require('@/assets/images/park-03.jpg'),
        require('@/assets/images/park-04.jpg'),
        require('@/assets/images/park-05.jpg'),
        require('@/assets/images/park-06.jpg'),
        require('@/assets/images/park-07.jpg'),
        require('@/assets/images/park-08.jpg'),
        require('@/assets/images/park-09.jpg'),
        require('@/assets/images/park-10.jpg'),
      ]
    }
  },
  mounted() {
    this.carouselHeight = this.$refs.carouselRef.$el.offsetWidth * 750 / 1520 + 'px'
    window.addEventListener("resize", () => {
      if (this.$refs.carouselRef) {
        this.carouselHeight = this.$refs.carouselRef.$el.offsetWidth * 750 / 1520 + 'px'
      }
    })
  },
}
</script>

<style scoped>
.park-title {
  font-size: 2rem;
  font-weight: 800;
}

.park-content {
  font-size: 1.125rem;
  line-height: 2.2rem;
  text-align: justify;
}

.investment-title {
  position: absolute;
  width: 100%;
  bottom: 15%;
  text-align: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 800;
  color: #fff;
}

.service-title {
  padding-top: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
}

/deep/ .el-carousel__arrow {
  width: 3.125rem;
  height: 3.125rem;
  font-size: 3.125rem;
  background-color: transparent;
}

/deep/ .el-carousel__arrow:hover {
  background-color: transparent;
}
</style>