<template>

<div class="footer">
<div class="footer-container">
<div class="center-content hidden-sm-and-down">
<el-row class="foot-top" :gutter="20">
<el-col :span="5">
<div class="foot-top-title common-font-size-20">
              威斯尼斯人wns145585
            </div>
<div class="foot-top-content common-font-size-16">
<p>
<router-link to="enterpriseIntro">企业介绍</router-link>
</p>
<p>
<router-link to="corporateCulture">企业文化</router-link>
</p>
<p>
<router-link to="milestone">里程碑</router-link>
</p>
<p>
<router-link to="qualification">资质荣誉</router-link>
</p>
<p>
<router-link to="contactUs">联系我们</router-link>
</p>
</div>
</el-col>
<el-col :span="5">
<div class="foot-top-title common-font-size-20">
              媒体中心
            </div>
<div class="foot-top-content common-font-size-16">
<p>
<router-link to="corporate-information">企业资讯</router-link>
</p>
<p>
<router-link to="media-coverage">媒体报道</router-link>
</p>
<p>
<router-link to="corporate-notice">企业公告</router-link>
</p>
</div>
</el-col>
<el-col :span="5">
<div class="foot-top-title common-font-size-20">
<router-link to="service">服务</router-link>
</div>
</el-col>
<el-col :span="5">
<div class="foot-top-title common-font-size-20">
              产业园
            </div>
<div class="foot-top-content common-font-size-16">
<p>
<router-link to="aboutPark">关于产业园</router-link>
</p>
<p>
<router-link to="park-information">园区资讯</router-link>
</p>
</div>
</el-col>
<el-col :span="4">
<div class="foot-top-title common-font-size-20 no-sub">
<router-link to="career">职业机会</router-link>
</div>
</el-col>
</el-row>
<el-divider></el-divider>
<el-row class="links">
<p><a href="http://gms.it-sunao.com/" target="_blank" class="common-font-size-16">综合管理系统</a></p>
</el-row>
</div>
<div class="center-content hidden-md-and-up mobile-footer-box">
<el-collapse accordion class="collapse-container">
<el-collapse-item title="威斯尼斯人wns145585" name="1">
<p>
<router-link to="enterpriseIntro" class="common-font-size-14">企业介绍</router-link>
</p>
<p>
<router-link to="corporateCulture" class="common-font-size-14">企业文化</router-link>
</p>
<p>
<router-link to="milestone" class="common-font-size-14">里程碑</router-link>
</p>
<p>
<router-link to="qualification" class="common-font-size-14">资质荣誉</router-link>
</p>
<p>
<router-link to="contactUs" class="common-font-size-14">联系我们</router-link>
</p>
</el-collapse-item>
<el-collapse-item title="媒体中心" name="2">
<p>
<router-link to="corporate-information" class="common-font-size-14">企业资讯</router-link>
</p>
<p>
<router-link to="media-coverage" class="common-font-size-14">媒体报道</router-link>
</p>
<p>
<router-link to="corporate-notice" class="common-font-size-14">企业公告</router-link>
</p>
</el-collapse-item>
<el-collapse-item title="服务" name="3" disabled @click.native="toPage('/service')">
</el-collapse-item>
<el-collapse-item title="产业园" name="4">
<p>
<router-link to="aboutPark" class="common-font-size-14">关于产业园</router-link>
</p>
<p>
<router-link to="park-information" class="common-font-size-14">园区资讯</router-link>
</p>
</el-collapse-item>
<el-collapse-item title="职业机会" name="5" disabled @click.native="toPage('/career')">
</el-collapse-item>
</el-collapse>
<el-row class="links">
<p><a href="http://gms.it-sunao.com/" target="_blank" class="font-14">综合管理系统</a></p>
</el-row>
</div>
</div>
</div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {}
  },
  methods: {
    toPage(path) {
      console.log(path)
      this.$router.push({
        path: path
      })
    }
  }
}
</script>

<style scoped>
.footer-container {
  background-color: #5E5E5E;
  padding: 3.125rem 0 1.875rem 0;
}

.foot-top {
  padding: 0 0.625rem;
}

.links p {
  margin: 0;
}

a {
  text-decoration: none;
}

p a {
  color: #f2f2f2;
}

.foot-top-title, .foot-top-title a {
  color: #f2f2f2;
}

.foot-top-title {
  line-height: 3rem;
  font-weight: 700;
}

.foot-top-content p {
  margin: 0;
  line-height: 1.875rem;
}

/deep/ .el-divider {
  background-color: #aaa;
}

/deep/ .el-collapse {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
}

/deep/ .el-collapse p a {
}

/deep/ .el-collapse-item__header {
  background-color: #5E5E5E;
  color: #f2f2f2;
  border-bottom: 1px solid #aaa;
  font-size: 15px;
  font-weight: 700;
}

/deep/ .el-collapse-item__wrap {
  will-change: height;
  background-color: #5E5E5E;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid #aaa;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
  color: #f2f2f2;
  line-height: 1.769230769230769;
}

/deep/ .el-collapse-item.is-disabled .el-collapse-item__header {
  color: #f2f2f2;
  cursor: pointer;
}

.collapse-container p {
  margin: 0;
  padding: 0.675rem 0;
  border-bottom: 1px solid #aaa;
}

.collapse-container p:last-child {
  border-bottom: none;
}

.collapse-container p a {
  padding-left: 0.625rem;
}

.mobile-footer-box .links {
  text-align: center;
  margin-top: 2rem;
}

.mobile-footer-box .links p {
  padding-left: 0;
}
</style>