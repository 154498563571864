<template>

<div class="mt-nav">
<static-banner :image_banner="image_banner" main-title="企业公告"/>

<NewsContent news-type="3" content-title="企业公告"></NewsContent>
</div>
</template>

<script>
import NewsContent from "@/components/NewsContent";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "CorporateNotice",
  components: {
    NewsContent,
    StaticBanner
  },
  data() {
    return {
      image_banner: require('@/assets/images/banner-company-announcement.jpg')
    }
  }
}
</script>

<style scoped>
</style>