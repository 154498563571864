<template>

<div class="info-com">
<router-link class="news-item common-border-radius transition-hover"
                 :to="{name:'InformationDetail', params:{id: news.newsId, newsType: news.newsType}}">
<div class="news-image-container">
<el-image class="news-cover img-transition-hover" fit="cover"
                  :src="news.newsCover?news.newsCover:defaultUrl">
<div slot="error" class="error_img">
<el-image class="news-cover img-transition-hover" fit="cover" :src="defaultUrl"></el-image>
</div>
</el-image>
</div>
<div class="news-info">
<div class="title">{{ news.newsTitle }}</div>
<div class="date common-font-size-14">{{ parseTime(news.publishTime, 'yyyy年m月d日') }}</div>
</div>
</router-link>
</div>
</template>

<script>

export default {
  name: "InformationCom",
  props: {
    news: Object
  },
  data() {
    return {
      defaultUrl: require('@/assets/images/news-default.png')
    }
  }
}
</script>

<style scoped>
.news-item {
  display: block;
  overflow: hidden;
  position: relative;
  -webkit-transform: rotate(0deg);
}

.news-image-container {
  width: 100%;
  padding-bottom: 66.67%;
  height: 0;
}

.el-image {
  display: flex;
}

.news-cover {
  width: 100%;
}

.news-info {
  background-color: #fff;
  bottom: 0;
  font-size: 1.25rem;
  height: 12.75rem;
  left: 0;
  padding: 1.5rem 1rem 1rem;
  position: relative;
  width: 100%;
}

.news-info .title {
  font-size: 1.25rem;
  line-height: 1.875rem;
  height: 9rem;
}

.news-info .date {
  margin: 0 auto;
  height: 1.25rem;
}

.transition-hover:hover .img-transition-hover {
  transform: scale(1.1);
}

.transition-hover:hover .news-info {
  background-color: #2c9fd0;
  color: #ffffff;
}

/deep/ .error_img {
  width: 100%;
  height: 100%;
}
</style>