<template>

<div class="copyright flex-row-center">
<div class="copyright-container common-font-size-14">
<div class="hidden-sm-and-down">
<span>© 2016-2023 澳门威斯尼斯人wns8 版权所有 增值电信业务经营许可证：</span>
<a href="http://beian.miit.gov.cn" target="_blank">吉ICP备11002002号-1</a>
<router-link class="ml-20" to="legal">法律声明</router-link>
</div>
<div class="hidden-md-and-up">
<div>© 2016-2023 澳门威斯尼斯人wns8 版权所有</div>
<div>
<span>增值电信业务经营许可证：</span>
<a href="http://beian.miit.gov.cn" target="_blank">吉ICP备11002002号-1</a>
</div>
<router-link to="legal">法律声明</router-link>
</div>
</div>
</div>
</template>

<script>
export default {
  name: "Copyright",
  data() {
    return {}
  }
}
</script>

<style scoped>
.copyright {
  background-color: #3d3d3d;
  padding: 1rem 0;
}

.copyright-container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  color: #f2f2f2;
  line-height: 1.5;
}

.copyright-container span {
  margin: 0;
}

a {
  color: #f2f2f2;
}

a:hover {
  text-decoration: underline;
  color: #2c9fd0;
}
</style>