<template>
<div class="banner-container position-relative">
<el-image :src="image_banner" fit="cover" style="height: 31.25rem;"/>
<div class="banner-content">
<div v-show="mainTitle" class="title-lg">{{ mainTitle }}</div>
<div v-show="subTitle" class="sub-title-lg">{{ subTitle }}</div>
</div>
</div>
</template>

<script>
export default {
  name: "StaticBanner",
  props: {
    image_banner: {
      type: String
    },
    mainTitle: {
      type: String
    },
    subTitle: {
      type: String
    }
  }
}
</script>

<style scoped>
@import "@/assets/style/news.css";
</style>