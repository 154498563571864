<template>

<div class="mt-nav">
<static-banner :image_banner="image_banner" main-title="园区资讯"
                   sub-title="以下是园区的最新动态"/>

<NewsContent news-type="4" content-title="园区资讯"></NewsContent>
</div>
</template>

<script>
import NewsContent from "@/components/NewsContent";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "ParkInformation",
  components: {
    NewsContent,
    StaticBanner
  },
  data() {
    return {
      image_banner: require('@/assets/images/banner-park-news.jpg')
    }
  }
}
</script>

<style scoped>
</style>