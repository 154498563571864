<template>

<div class="mt-nav bgcolor-white">
<static-banner :image_banner="image_banner" main-title="加入我们" sub-title="投个简历，交个朋友"/>

<div class="pt-4r">
<el-row class="center-content">
<el-col :span="24">
<horizontal-text content="职业机会"/>
</el-col>
<el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" class="pt-2r text-center" style="position: relative;">
<el-image :src="job_1" fit="fill" style="width: 28.125rem;"/>
<div class="chance-title">
<div>热招中</div>
<div class="chance-button">
<el-button @click="handleClick">社会招聘</el-button>
</div>
</div>
</el-col>
<el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" class="pt-2r text-center" style="position: relative;">
<el-image :src="job_2" fit="fill" style="width: 28.125rem;"/>
<div class="chance-title">
<div>暂未启动</div>
<div class="chance-button">
<el-button disabled>校园招聘</el-button>
</div>
</div>
</el-col>
</el-row>
<div class="pt-4r"></div>
</div>
<div class="bgcolor-gray pt-4r">
<div class="center-content">
<horizontal-text content="在一起，有未来！" class="text-center"/>
<div class="pt-2r"></div>
<el-carousel indicator-position="none" :interval="2000" :height="carouselHeight" ref="carouselRef">
<el-carousel-item v-for="(item, index) in banners" :key="index">
<el-image :src="item" fit="fill"/>
</el-carousel-item>
</el-carousel>
<div class="pt-4r"></div>
</div>
</div>
</div>
</template>

<script>
import HorizontalText from "@/components/HorizontalText";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "CareerOpportunities",
  components: {HorizontalText, StaticBanner},
  data() {
    return {
      image_banner: require('@/assets/images/banner-hiring.jpg'),
      job_1: require('@/assets/images/job-1.jpg'),
      job_2: require('@/assets/images/job-2.jpg'),
      carouselHeight: "",
      banners: [
        require('@/assets/images/activity-1.jpg'),
        require('@/assets/images/activity-2.jpg'),
        require('@/assets/images/activity-3.jpg'),
        require('@/assets/images/activity-4.jpg'),
        require('@/assets/images/activity-5.jpg'),
        require('@/assets/images/activity-6.jpg'),
        require('@/assets/images/activity-7.jpg'),
        require('@/assets/images/activity-8.jpg'),
        require('@/assets/images/activity-9.jpg'),
        require('@/assets/images/activity-10.jpg'),
      ]
    }
  },
  mounted() {
    this.carouselHeight = this.$refs.carouselRef.$el.offsetWidth * 750 / 1520 + 'px'
    window.addEventListener("resize", () => {
      if (this.$refs.carouselRef) {
        this.carouselHeight = this.$refs.carouselRef.$el.offsetWidth * 750 / 1520 + 'px'
      }
    })
  },
  methods: {
    handleClick() {
      window.open("https://sou.zhaopin.com/?jl=613&kw=%E9%95%BF%E6%98%A5%E6%96%AF%E7%BA%B3%E6%AC%A7%E8%BD%AF%E4%BB%B6%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8", "_blank");
    }
  }
}
</script>

<style scoped>
.chance-title {
  position: absolute;
  width: 100%;
  height: 75%;
  top: 25%;
  text-align: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 800;
}

.chance-button {
  position: absolute;
  text-align: center;
  align-items: center;
  bottom: 25%;
  width: 100%;
}

/deep/ .el-carousel__arrow {
  width: 3.125rem;
  height: 3.125rem;
  font-size: 3.125rem;
  background-color: transparent;
}

/deep/ .el-carousel__arrow:hover {
  background-color: transparent;
}

/deep/ .el-button {
  background: hsla(0, 0%, 100%, 0.85);
  border-radius: 5px;
  color: #222;
  width: 190px;
}

/deep/ .el-button:focus, /deep/ .el-button:hover {
  background-color: #eee;
  color: #222;
}
</style>