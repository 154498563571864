import { render, staticRenderFns } from "./NewsContent.vue?vue&type=template&id=af486dc0&scoped=true&"
import script from "./NewsContent.vue?vue&type=script&lang=js&"
export * from "./NewsContent.vue?vue&type=script&lang=js&"
import style0 from "./NewsContent.vue?vue&type=style&index=0&id=af486dc0&prod&scoped=true&lang=css&"



import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af486dc0",
  null
  
)

export default component.exports