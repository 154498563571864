<template>
<div class="mt-nav bgcolor-white">
<div class="center-content">
<div class="title">法律声明</div>
<p class="content">
        本网站由澳门威斯尼斯人wns8创建并运行维护。任何人士进入本网站、阅读任何内容、从本网站下载任何材料或使用本网站提供的资料，即表示同意遵守下列条款。这些条款构成澳门威斯尼斯人wns8与您之间的协议。若不同意遵守这些条款，切勿使用本网站。澳门威斯尼斯人wns8保留未经通知随时更新下列条款的权利，这些更新将同样也约束您。任何时间，任何情况下，澳门威斯尼斯人wns8均有权拒绝任何用户进入、使用本网站。
      </p>
<p class="content">
        澳门威斯尼斯人wns8保留随时更正、修改、更新本声明的权利。
      </p>
<div class="sub-title">网站使用</div>
<p class="content">
        本网站的内容仅供您个人而非商业性使用，对于内容中所含的任何权利声明您应予以遵守。即使本网站的内容没有相关权利声明，也并不代表本公司对其不享有权利，您应尊重这些内容的合法权益并遵守相关法律规定。
      </p>
<p class="content">
        您不得以任何方式修改、复制、公布、出租、出售或以其他方式将本网站内容用于任何公开或商业目的，禁止以任何目的把本网站内容用于任何其他网站或媒体。
      </p>
<p class="content">
        本公司可以随时对您访问或使用本网站进行任何修改、限制、暂停或终止，而无须事先通知，同时，也不对因此带给您或第三方的任何影响负责。
      </p>
<p class="content">
        本网站的内容受到法律法规的保护，若您违反了本使用条款，您应承担由此造成的一切后果和法律责任。
      </p>
<div class="sub-title">网站信息</div>
<p class="content">
        本网站的信息按原样提供，不附加任何形式的明示或默示的保证。本公司已经采取谨慎措施确保登载的信息的准确性，但不保证本网站信息的绝对准确性和绝对完整性。对于与本网站所提供信息的准确性、安全性等相关的问题，本公司概不负责，并且使用此类信息所带来的结果和风险由您自行承担。
      </p>
<p class="content">
        本公司可以随时修改或更新本网站的信息而不另行通知，修改或更新后的内容一经登载在本网站上即发生效力，请您定期访问。
      </p>
<div class="sub-title">网站链接</div>
<p class="content">
        您可能通过本网站访问任何其它的站点，本公司只是为了您的方便提供该等链接，同时本公司保留在任何时候中止任何链接的权利。链接的站点独立于本网站，本网站不能控制该站点上的内容，也不对该站点上的内容和信息承担任何责任。对于访问与本网站链接的任何其它站点，所带来的结果和风险全部由您自身承担。
      </p>
<div class="sub-title">知识产权</div>
<p class="content">
        未经本公司的书面同意，不得对本网站的信息、文字、音乐、图片、商标、标识以及其他内容进行传播、修改、复制、发行、出租、出售或以其他方式使用，不得对本网站的任何软件进行下载、传播、修改、复制、出租、出售、反编译、反向工程等，不得删除或修改任何版权、商标权、专利权或其他所有权的声明。
      </p>
<p class="content">
        本公司没有以任何方式对您授予任何知识产权许可，请您遵守相关法律法规。
      </p>
<div class="sub-title">责任限制</div>
<p class="content">
        本公司对于任何因本网站使用或链接所引起的任何直接的、间接的、惩罚性的、特殊的、偶然的损失概不负责，包括但不限于业务、收入、利润、信誉、数据或其他损失，不管它们是否有保证、合同、侵权行为或任何其它法律根据以及事前已得到这类损害可能发生的忠告。如果您由于使用本网站而导致需要对数据或设备进行维护、修理或纠正，由此产生的任何费用全部由您自身承担。
      </p>
<div class="sub-title">法律适用和管辖</div>
<p class="content">
        本使用条款或有关使用本网站的任何行为受中华人民共和国法律管辖，因此发生争议的，双方协商解决。如协商不成时，您同意由本公司法定地址所在地的人民法院作出裁决。
      </p>
<p class="content">
        本使用条款的解释权以及有关本网站使用的解释权属于本公司所有。
      </p>
<div class="pt-4r"></div>
</div>
</div>
</template>

<script>
export default {
  name: "Legal"
}
</script>

<style scoped>
.title {
  text-align: center;
  font-size: 2.25rem;
  font-weight: 700;
  padding: 4rem 0 2rem 0;
}

.sub-title {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem 0;
}

.content {
  text-align: justify;
  font-size: 1.125rem;
  line-height: 2.2rem;
}
</style>