import Home from "@/views/Home/Home";
import EnterpriseIntro from "@/views/AboutUs/EnterpriseIntro";
import CorporateCulture from "@/views/AboutUs/CorporateCulture";
import Milestone from "@/views/AboutUs/Milestone";
import Qualification from "@/views/AboutUs/Qualification";
import ContactUs from "@/views/AboutUs/ContactUs";
import CareerOpportunities from "@/views/CareerOpportunities";
import Service from "@/views/Service";
import CorporateInformation from "@/views/MediaCenter/CorporateInformation";
import MediaCoverage from "@/views/MediaCenter/MediaCoverage";
import CorporateNotice from "@/views/MediaCenter/CorporateNotice";
import ParkInformation from "@/views/IndustrialPark/ParkInformation";
import AboutPark from "@/views/IndustrialPark/AboutPark";
import InformationDetail from "@/views/MediaCenter/InformationDetail";
import Legal from "@/views/Legal";

const routes = [
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    // 威斯尼斯人wns145585-企业介绍
    {
        path: '/enterpriseIntro',
        name: 'enterpriseIntro',
        component: EnterpriseIntro
    },
    // 威斯尼斯人wns145585-企业文化
    {
        path: '/corporateCulture',
        name: 'corporateCulture',
        component: CorporateCulture
    },
    // 威斯尼斯人wns145585-里程碑
    {
        path: '/milestone',
        name: 'milestone',
        component: Milestone
    },
    // 威斯尼斯人wns145585-资质荣誉
    {
        path: '/qualification',
        name: 'qualification',
        component: Qualification
    },
    // 威斯尼斯人wns145585-联系我们
    {
        path: '/contactUs',
        name: 'contactUs',
        component: ContactUs
    },
    // 媒体中心-企业资讯
    {
        path: '/corporate-information',
        name: 'CorporateInformation',
        component: CorporateInformation,

    },
    // 资讯-详情
    {
        path: '/information/:newsType/:id',
        name: 'InformationDetail',
        component: InformationDetail,
        props: true
    },
    // 首页资讯-详情
    {
        path: '/information/:id',
        name: 'HomeNewsDetail',
        component: InformationDetail,
        props: true
    },
    // 媒体中心-媒体报道
    {
        path: '/media-coverage',
        name: 'MediaCoverage',
        component: MediaCoverage
    },
    // 媒体中心-企业公告
    {
        path: '/corporate-notice',
        name: 'CorporateNotice',
        component: CorporateNotice
    },
    // 产业园-园区资讯
    {
        path: '/park-information',
        name: 'ParkInformation',
        component: ParkInformation
    },
    // 产业园-关于产业园
    {
        path: '/aboutPark',
        name: 'AboutPark',
        component: AboutPark
    },
    // 职业机会
    {
        path: '/career',
        name: 'career',
        component: CareerOpportunities
    },
    // 服务
    {
        path: '/service',
        name: 'service',
        component: Service
    },
    // 法律声明
    {
        path: '/legal',
        name: 'legal',
        component: Legal
    },
    {
        path: '/',
        component: Home
    }
]

export default routes