import { render, staticRenderFns } from "./MediaCoverage.vue?vue&type=template&id=376c1bfa&scoped=true&"
import script from "./MediaCoverage.vue?vue&type=script&lang=js&"
export * from "./MediaCoverage.vue?vue&type=script&lang=js&"



import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376c1bfa",
  null
  
)

export default component.exports